import {
	ColorPreset,
	DurationInfo,
	Guid,
	PeriodicInfo,
	Periodicity,
	ProductClient,
	ProductCurrency,
	productCurrencyItems,
	ProductEligibility,
	productEligibilityItems,
	ProductLaw,
	productLawItems,
	StrikeFeature,
	UnderlyingIndexationType,
	UnderlyingType,
} from 'Domain/report';

import * as yup from 'yup';
import { DateTime } from 'luxon';
import {
	AthenaClientConfiguration,
	AthenaRedemptionConfiguration,
	AthenaReportConfiguration,
	AthenaScheduleLine,
	AthenaTransversalConfiguration,
	AthenaUnderlyingConfiguration,
	AthenaUnderlyingId,
} from 'Domain/report.athena';
import { isNil } from 'lodash';
import { setValidationDefaultLocale } from 'Helpers/validationHelpers';

setValidationDefaultLocale();

export const transversalConfigurationSchema = (): yup.Schema<AthenaTransversalConfiguration> =>
	yup.object({
		productName: yup.string().defined().min(1),
		productCotation: yup.string().defined(),
		calculationAgent: yup.string().defined(),
		productIssuer: yup.string().defined(),
		hasMarketingPeriod: yup.bool().defined(),
		marketingPeriod: yup
			.object({
				startDate: yup.date().required(),
				endDate: yup.date().required(),
			})
			.when('hasMarketingPeriod', { is: true, then: (s) => s.required() }),
		minimumInvestment: yup.number().defined(),
		productGuarantor: yup.string().defined(),
		bidOfferPercent: yup.number().defined(),
		productCurrency: yup
			.mixed<ProductCurrency>()
			.oneOf(Object.values(productCurrencyItems).map((e) => e.key))
			.defined(),
		isin: yup.string().defined(),
		mtmPublication: yup.string().required().min(1),
		issuePrice: yup.number().defined(),
		priceRate: yup.number().defined(),
		fTDate: yup.date(),
		doubleValuation: yup.string().defined(),
		productDenomination: yup.number().min(1).defined(),
		productLaw: yup
			.mixed<ProductLaw>()
			.oneOf(Object.values(productLawItems).map((e) => e.key))
			.defined(),
		commission: yup.number().defined(),
		commissionType: yup.string().defined(),
		productEligibility: yup
			.mixed<ProductEligibility>()
			.required()
			.oneOf(Object.values(productEligibilityItems).map((e) => e.key)),
		initialValuationDate: yup.date().defined(),
		issueDate: yup.date().defined(),
		maturityDate: yup
			.date()
			.defined()
			.min(DateTime.utc().plus({ day: 1 }).startOf('day')),
		productDuration: yup
			.mixed<DurationInfo>()
			.defined()
			.test('Product Duration', 'Please set product duration or click on button above to compute duration.', (value, b) => {
				if (value.days <= 0 && value.months <= 0 && value.years <= 0) {
					return false;
				}
				return true;
			}),

		capitalPaymentBusinessDays: yup.number(),

		couponPaymentDates: yup.array().of(yup.date().defined()).optional(),
	});

export const redemptionSchema = () =>
	yup
		.object<AthenaRedemptionConfiguration>()
		.defined()
		.shape({
			observationStartPeriod: yup.number().defined(),
			observationPeriodicity: yup.mixed<Periodicity>().defined(),
			autocallBarrier: yup.number().defined(),
			coupon: yup.number().defined(),
			capitalProtectionBarrier: yup.number().defined(),
			isLeveraged: yup.bool().defined(),
			leverageAmount: yup
				.number()
				.optional()
				.when('isLeveraged', { is: true, then: (s) => s.required().min(0) }),
			isOxygen: yup.bool().defined(),
			oxygenBarrier: yup
				.number()
				.optional()
				.when('isOxygen', { is: true, then: (s) => s.required().min(0) }),

			// Degressivity
			isAutocallDegressive: yup.bool().defined(),
			degressivityPeriod: yup
				.object<PeriodicInfo>()
				.shape({
					duration: yup.number().defined(),
					period: yup.mixed<Periodicity>().defined(),
				})
				.optional()
				.when('isAutocallDegressive', {
					is: true,
					then: (s) =>
						s.shape({
							duration: yup.number().min(1).defined(),
							period: yup.mixed<Periodicity>().defined(),
						}),
				}),
			degressivityStep: yup
				.number()
				.optional()
				.when('isAutocallDegressive', { is: true, then: (s) => s.required().min(0) }),

			// Payment dates
			scheduleLines: yup.array<AthenaScheduleLine>().defined(),
			totalPeriods: yup.number().defined(),
			// Strike
			strikeFeature: yup.mixed<StrikeFeature>().required(),
			strikeDates: yup
				.array<Date>()
				.defined()
				.when('strikeFeature', { is: (v: StrikeFeature) => v !== StrikeFeature.None, then: (s) => s.required().min(0) }),
			strikeInitialPaiementDate: yup
				.date()
				.optional()
				.when('strikeFeature', { is: (v: StrikeFeature) => v !== StrikeFeature.None, then: (s) => s.required() }),
			capitalPaymentBusinessDays: yup.number().optional(),
			couponPaymentBusinessDays: yup.number().optional(),
			feesPercent: yup.number().defined(),
			isCapitalGuarantee: yup.bool().defined(),
		});

export const clientConfigurationSchema = () =>
	yup.object<AthenaClientConfiguration>().shape({
		client: yup.mixed<ProductClient>().required(),
		pictureId: yup.mixed<Guid>().defined().required(),
		colorPreset: yup.mixed<ColorPreset>().defined().required(),
	});

export const underlyingConfigurationSchema = () =>
	yup
		.object<AthenaUnderlyingConfiguration>()
		.defined()
		.shape({
			underlyings: yup.array<AthenaUnderlyingId>(underlyingIdSchema(false)).defined(),
			indexationType: yup.mixed<UnderlyingIndexationType>().defined(),
		});
export const underlyingIdSchema = (_: boolean) =>
	yup
		.object<AthenaUnderlyingId>()
		.defined()
		.shape({
			type: yup.mixed<UnderlyingType>().defined(),
			bloombergTickerCode: yup.string().defined(),
			underlyingName: yup.string().defined(),
			isinCode: yup.string().defined(),
			lastCLose: yup.number().optional(),
			isValid: yup
				.bool()
				.isTrue(
					'Only valid underlyings can be used to generate the brochure. Please remove all unknownn underlyings or ask an Administrator to add a new Underlying in the database.'
				)
				.defined(),
		});

export function getAthenaValidationSchema(): yup.Schema<AthenaReportConfiguration> {
	const schema: yup.Schema<AthenaReportConfiguration> = yup
		.object()
		.shape({
			clientConfiguration: clientConfigurationSchema(),
			underlyingConfiguration: underlyingConfigurationSchema(),
			redemptionConfiguration: redemptionSchema(),
			transversalConfiguration: transversalConfigurationSchema(),
		})
		.test(
			'Strike validation',
			'When Strike feature is Enabled, you have to specify the strike to use on every underlying if not already specified',
			(value, b) => {
				if (value.redemptionConfiguration.strikeFeature !== StrikeFeature.None) {
					const underlyingWithMissingStrikeValues = (value.underlyingConfiguration.underlyings as AthenaUnderlyingId[]).filter((udlId) => {
						return isNil(udlId.lastClose);
					});
					return underlyingWithMissingStrikeValues.length <= 0;
				}
				return true;
			}
		);
	return schema;
}
