import { AthenaTransversalConfiguration } from "Domain/report.athena";
import { AthenaTransversalConfigurationDto } from "Domain/reportDto.athena";
import { DurationInfoMappers } from "Mappers/durationInfoMappers";
import { productCurrencyMapper } from "Mappers/productCurrencyMapper";
import { computeDuration } from "Mappers/Tarn/tarnTransversalConfigurationMapper";
import { toDateOnly, toJSDate } from "Utilities/date";


function mapEntityToDto(entity: AthenaTransversalConfiguration): AthenaTransversalConfigurationDto {
	const dto: AthenaTransversalConfigurationDto = {
		productName: entity.productName,
		productCotation: entity.productCotation,
		calculationAgent: entity.calculationAgent,
		productIssuer: entity.productIssuer,
		hasMarketingPeriod: entity.hasMarketingPeriod,
		marketingStart: entity.hasMarketingPeriod && entity.marketingPeriod?.startDate ? toDateOnly(entity.marketingPeriod?.startDate) : undefined,
		marketingEnd: entity.hasMarketingPeriod && entity.marketingPeriod?.endDate ? toDateOnly(entity.marketingPeriod?.endDate) : undefined,
		minimumInvestment: entity.minimumInvestment,
		productGuarantor: entity.productGuarantor,
		bidOfferPercent: entity.bidOfferPercent,
		productCurrency: entity.productCurrency,
		isin: entity.isin,
		mtmPublication: entity.mtmPublication,
		issuePrice: entity.issuePrice,
		priceRate: entity.priceRate,
		ftDate: toDateOnly(entity.ftDate),
		doubleValuation: entity.doubleValuation,
		productDenomination: entity.productDenomination,
		productLaw: entity.productLaw,
		commission: entity.commission,
		commissionType: entity.commissionType,
		productEligibility: entity.productEligibility,
		maturityDate: toDateOnly(entity.maturityDate),
		issueDate: toDateOnly(entity.issueDate),
		productDuration: DurationInfoMappers.mapEntityToDto(entity.productDuration),
		initialValuationDate: toDateOnly(entity.initialValuationDate),
	};
	return dto;
}
function mapDtoToEntity(dto: AthenaTransversalConfigurationDto): AthenaTransversalConfiguration {
	const issueDate = toJSDate(dto.issueDate, new Date());
	const maturityDate = toJSDate(dto.maturityDate, new Date());
	const entity: AthenaTransversalConfiguration = {
		productName: dto.productName,
		productCotation: dto.productCotation,
		calculationAgent: dto.calculationAgent,
		productIssuer: dto.productIssuer,
		marketingPeriod: dto.hasMarketingPeriod
			? {
				startDate: toJSDate(dto.marketingStart, new Date()),
				endDate: toJSDate(dto.marketingEnd, new Date()),
			}
			: undefined,
		hasMarketingPeriod: dto.hasMarketingPeriod,
		minimumInvestment: dto.minimumInvestment,
		productGuarantor: dto.productGuarantor,
		bidOfferPercent: dto.bidOfferPercent,
		productCurrency: productCurrencyMapper.mapDtoToEntity(dto.productCurrency),
		isin: dto.isin,
		mtmPublication: dto.mtmPublication ?? '',
		issuePrice: dto.issuePrice,
		priceRate: dto.priceRate,
		ftDate: toJSDate(dto.ftDate),
		doubleValuation: dto.doubleValuation ?? '',
		productDenomination: dto.productDenomination,
		productLaw: dto.productLaw,
		commission: dto.commission,
		commissionType: "placés",
		productEligibility: dto.productEligibility,
		issueDate: issueDate,
		maturityDate: maturityDate,
		productDuration: computeDuration(issueDate, maturityDate),
		initialValuationDate: toJSDate(dto.initialValuationDate, new Date()),
	};
	return entity;
}
export const athenaTransversalConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
