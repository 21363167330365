import { useMemo } from "react";
import { TarnReportConfiguration } from "Domain/report.tarn";
import { BrochureGeneratorAppState } from "Domain/report";
import { useSelector } from "react-redux";
import { getFielValidationState } from "../Athena/useAthenaValidationStateForField";

export function useTarnValidationStateForField<
	TReportConfigKey extends keyof TarnReportConfiguration,
	TFieldKey extends keyof TarnReportConfiguration[TReportConfigKey],
>(key: TReportConfigKey, field: TFieldKey): string | undefined {
	const validationState = useSelector((s: BrochureGeneratorAppState) => s.tarn.formState.validationState);
	const fieldState = useMemo(
		() => getFielValidationState(validationState, key, field as string),
		[field, key, validationState],
	);
	return fieldState;
}
