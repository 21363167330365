
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TauxFixeReportConfiguration } from 'Domain/report.tauxfixe';
import { BrochureGeneratorAppState } from 'Domain/report';
import { getFielValidationState } from '../Athena/useAthenaValidationStateForField';

export function useTauxFixeValidationStateForField<TReportConfigKey extends keyof TauxFixeReportConfiguration, TFieldKey extends keyof TauxFixeReportConfiguration[TReportConfigKey]>(
	key: TReportConfigKey,
	field: TFieldKey
): string | undefined {
	const validationState = useSelector((s: BrochureGeneratorAppState) => s.tauxFixe.formState.validationState);
	const fieldState = useMemo(
		() => getFielValidationState(validationState, key, field as string),
		[field, key, validationState],
	);
	return fieldState;
}
