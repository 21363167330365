import { GlobalConfiguration } from "@analytics-web-core/core";
import { BaseApplicationState } from "@analytics-web-core/core/dist/Configuration/BaseApplicationState";
import { LabelsConfiguration } from "components/Shared/FormControls/FieldConfiguration";
import { AuthorizationState } from "state/authorization/authorizationReducer";
import { HomePageState } from "state/HomePage/HomePage.state";
import { AthenaState } from "state/Reports/Athena/Athena.state";
import { PhoenixBearishState } from "state/Reports/PhoenixBearish/PhoenixBearish.state";
import { TarnState } from "state/Reports/Tarn/tarn.state";
import { TauxFixeState } from "state/Reports/TauxFixe/tauxFixe.state";
import { ResourcesState } from "state/Resources/Resources.state";

export type Guid = string;

export enum StrikeFeature {
	None = "None",
	Min = "Min",
	Mid = "Mid",
}

export enum AvailableLanguages {
	FR = "FR",
	EN = "EN",
}

export enum Periodicity {
	Years = "Years",
	Semesters = "Semesters",
	Quarters = "Quarters",
	Months = "Months",
}

export enum ProductLaw {
	French = "Francais",
	English = "Anglais",
}

export enum UnderlyingIndexationType {
	None = "None",
	WorstOf = "WorstOf",
	Basket = "Basket",
}

export enum UnderlyingType {
	Stocks = "Stocks",
	Indices = "Indices",
	Funds = "Funds",
}

export enum ProductClient {
	PWM = "PWM",
	Adequity = "Adequity",
	SwissLife = "SwissLife",
	NexoCapital = "NexoCapital",
	IKapital = "IKapital",
	BoursoBank = "BoursoBank",
}

export enum ProductEligibility {
	CompteTitre = "CompteTitre",
	AssuranceVieCapitalisation = "AssuranceVieCapitalisation",
	CompteTitreAssuranceVieCapitalisation = "CompteTitreAssuranceVieCapitalisation",
	CompteTitreNonPublicationProspectus = "CompteTitreNonPublicationProspectus",
	CompteTitreAssuranceVieCapitalisationNonPublicationProspectus = "CompteTitreAssuranceVieCapitalisationNonPublicationProspectus",
}

export enum BelowChoices {
	LessThanOrEqualTo = "LessThanOrEqualTo",
	StrictlyLessThan = "StrictlyLessThan",
}

export enum CommissionType {
	Placed = "placés",
	Distributed = "distribués",
}

export enum ProductCurrency {
	EUR = "EUR",
	USD = "USD",
	GPB = "GBP",
}

export const reportTypeItems: LabelsConfiguration<Exclude<ReportTypeKeys, "Unknown" | "Phoenix">> = {
	Athena: {
		key: "Athena",
		label: "Athena",
	},

	PhoenixBearish: {
		key: "PhoenixBearish",
		label: "Phoenix Bearish",
	},

	Tarn: {
		key: "Tarn",
		label: "Tarn",
	},

	TarnZC: {
		key: "TarnZC",
		label: "TarnZC",
	},

	TauxFixe: {
		key: "TauxFixe",
		label: "Taux Fixe",
	},
};

export const periodicityItems: LabelsConfiguration<Periodicity> = {
	[Periodicity.Years]: { key: Periodicity.Years, label: "Years" },
	[Periodicity.Months]: { key: Periodicity.Months, label: "Months" },
	[Periodicity.Quarters]: { key: Periodicity.Quarters, label: "Quarters" },
	[Periodicity.Semesters]: { key: Periodicity.Semesters, label: "Semesters" },
};

export const belowItems: LabelsConfiguration<BelowChoices> = {
	[BelowChoices.LessThanOrEqualTo]: { key: BelowChoices.LessThanOrEqualTo, label: "<=" },
	[BelowChoices.StrictlyLessThan]: { key: BelowChoices.StrictlyLessThan, label: "<" },
};

export const productLawItems: LabelsConfiguration<ProductLaw> = {
	[ProductLaw.English]: { key: ProductLaw.English, label: "English Law" },
	[ProductLaw.French]: { key: ProductLaw.French, label: "French Law" },
};
export const productClientItems: LabelsConfiguration<ProductClient> = {
	[ProductClient.Adequity]: { key: ProductClient.Adequity, label: "Adequity" },
	[ProductClient.PWM]: { key: ProductClient.PWM, label: "PWM" },
	[ProductClient.SwissLife]: { key: ProductClient.SwissLife, label: "Swiss Life" },
	[ProductClient.IKapital]: { key: ProductClient.IKapital, label: "I-Kapital" },
	[ProductClient.NexoCapital]: { key: ProductClient.NexoCapital, label: "Nexo Capital" },
	[ProductClient.BoursoBank]: { key: ProductClient.BoursoBank, label: "BoursoBank" },
};
export const underlyingIndexationTypeItems: LabelsConfiguration<UnderlyingIndexationType> = {
	[UnderlyingIndexationType.Basket]: { key: UnderlyingIndexationType.Basket, label: "Basket" },
	[UnderlyingIndexationType.None]: { key: UnderlyingIndexationType.None, label: "None" },
	[UnderlyingIndexationType.WorstOf]: { key: UnderlyingIndexationType.WorstOf, label: "WorstOf" },
};
export const productCurrencyItems: LabelsConfiguration<ProductCurrency> = {
	[ProductCurrency.EUR]: { key: ProductCurrency.EUR, label: "EUR" },
	[ProductCurrency.USD]: { key: ProductCurrency.USD, label: "USD" },
	[ProductCurrency.GPB]: { key: ProductCurrency.GPB, label: "GBP" },
};

export const commissionTypeItems: LabelsConfiguration<CommissionType> = {
	[CommissionType.Placed]: { key: CommissionType.Placed, label: "placés" },
	[CommissionType.Distributed]: { key: CommissionType.Distributed, label: "distribués" },
};

export const productEligibilityItems: LabelsConfiguration<ProductEligibility> = {
	[ProductEligibility.AssuranceVieCapitalisation]: {
		key: ProductEligibility.AssuranceVieCapitalisation,
		label:
			"Unité de compte d'un contrat d'assurance vie, de capitalisation ou de retraite (Plan d'EpargneRetraiteIndividuel-«PERIn»)",
	},
	[ProductEligibility.CompteTitre]: { key: ProductEligibility.CompteTitre, label: "Compte titres" },
	[ProductEligibility.CompteTitreAssuranceVieCapitalisation]: {
		key: ProductEligibility.CompteTitreAssuranceVieCapitalisation,
		label:
			"Compte titres et unité de compte d’un contrat d’assurance vie, de capitalisation ou de retraite (Plan d’EpargneRetraiteIndividuel-«PERIn»)",
	},
	[ProductEligibility.CompteTitreAssuranceVieCapitalisationNonPublicationProspectus]: {
		key: ProductEligibility.CompteTitreAssuranceVieCapitalisationNonPublicationProspectus,
		label:
			"Compte titres (dans le cadre d'une offre au public dispensée de la publication du prospectus) et unité de compte d’un contrat d’assurance vie, de capitalisation ou de retraite (Plan d’EpargneRetraiteIndividuel-«PERIn»)",
	},
	[ProductEligibility.CompteTitreNonPublicationProspectus]: {
		key: ProductEligibility.CompteTitreNonPublicationProspectus,
		label: "Compte titres (dans le cadre d'une offre au public dispensée de la publication du prospectus)",
	},
};
export const strikeFeatureItems: LabelsConfiguration<StrikeFeature> = {
	[StrikeFeature.None]: { key: StrikeFeature.None, label: "None", hidden: true },
	[StrikeFeature.Min]: { key: StrikeFeature.Min, label: "Min" },
	[StrikeFeature.Mid]: { key: StrikeFeature.Mid, label: "Mid" },
};

export type ColorPreset = {
	name: string;
	accent1: ColorProfile;
	accent2: ColorProfile;
	accent3: ColorProfile;
};

export type ColorProfile = {
	name?: string;
	r: number;
	g: number;
	b: number;
	a?: number;
};

export type PeriodicInfo = {
	duration: number;
	period: Periodicity;
};

export type DateRange = {
	startDate: Date;
	endDate: Date;
};

export enum ReportType {
	Unknown = "Unknown",
	Athena = "Athena",
	Phoenix = "Phoenix",
	Tarn = "Tarn",
	TarnZC = "TarnZC",
	TauxFixe = "TauxFixe",
	PhoenixBearish = "PhoenixBearish",
}
export type ReportTypeKeys = keyof typeof ReportType;

export type ValidationResult = {
	errorMessage: string;
	fields: string[];
};
export interface ReportInfoItem {
	id: Guid;
	name: string;
	isin?: string;
	tradeReference?: string;
	type: ReportType;
	status: ReportStatus;
	creationDate: Date;
	lastUpdateDate?: Date;
	processingDate?: Date;
	errors: ValidationResult[];
	availableDocuments: ReportDocumentType[];
}

export interface BaseReportModel {
	type: ReportType;
	tradeReference: string | undefined;
}

export enum ReportStatus {
	Unknown = "Unknown",
	Created = "Created",
	Processing = "Processing",
	Processed = "Processed",
	Error = "Error",
}

export enum ReportDocumentType {
	Unknown = "Unknown",
	Brochure = "Brochure",
	BrochurePdf = "BrochurePdf",
	TraTable = "TraTable",
}

export type DurationInfo = {
	years: number;
	months: number;
	days: number;
};

export type ReportStatusKeys = keyof typeof ReportStatus;

export enum ColorPresetTheme {
	Adequity = "Adequity",
	SwissLife = "SwissLife",
	IKapital = "IKapital",
	Vital = "Vital",
	Lynceus = "Lynceus",
	Marigny = "Marigny",
	NexoCapital = "Nexo Capital",
	BoursoBank = "BoursoBank",
}

export const ColorPresetThemes: Record<ColorPresetTheme, ColorPreset> = {
	[ColorPresetTheme.Adequity]: {
		name: "SG / Adequity",
		accent1: { r: 230, g: 0, b: 40 },
		accent2: { r: 1, g: 0, b: 53 },
		accent3: { r: 242, g: 242, b: 242 },
	},
	[ColorPresetTheme.SwissLife]: {
		name: "Swiss Life",
		accent1: { r: 230, g: 40, b: 40 },
		accent2: { r: 175, g: 171, b: 171 },
		accent3: { r: 236, g: 236, b: 236 },
	},
	[ColorPresetTheme.IKapital]: {
		name: "I-Kapital",
		accent1: { r: 28, g: 99, b: 147 },
		accent2: { r: 1, g: 0, b: 53 },
		accent3: { r: 242, g: 242, b: 242 },
	},
	[ColorPresetTheme.Vital]: {
		name: "Vital",
		accent1: {
			r: 187,
			g: 175,
			b: 147,
		},
		accent2: {
			r: 60,
			g: 60,
			b: 60,
		},
		accent3: {
			r: 242,
			g: 242,
			b: 242,
		},
	},
	[ColorPresetTheme.Lynceus]: {
		name: "Lynceus",
		accent1: {
			r: 219,
			g: 145,
			b: 53,
		},
		accent2: {
			r: 19,
			g: 52,
			b: 73,
		},
		accent3: {
			r: 242,
			g: 242,
			b: 242,
		},
	},
	[ColorPresetTheme.Marigny]: {
		name: "Marigny",
		accent1: {
			r: 235,
			g: 100,
			b: 68,
		},
		accent2: {
			r: 1,
			g: 0,
			b: 53,
		},
		accent3: {
			r: 242,
			g: 242,
			b: 242,
		},
	},
	[ColorPresetTheme.NexoCapital]: {
		name: "Nexo Capital",
		accent1: {
			r: 230,
			g: 0,
			b: 40,
		},
		accent2: {
			r: 1,
			g: 0,
			b: 53,
		},
		accent3: {
			r: 242,
			g: 242,
			b: 242,
		},
	},
	[ColorPresetTheme.BoursoBank]: {
		name: "BoursoBank",
		accent1: {
			r: 0,
			g: 56,
			b: 131,
		},
		accent2: {
			r: 210,
			g: 0,
			b: 115,
		},
		accent3: {
			r: 242,
			g: 242,
			b: 242,
		},
	},
};

export type PictureInfo = {
	id: string;
	name: string;
	imageUrl: string | undefined;
};
export const DefaultColorPresetTheme = ColorPresetTheme.Adequity;
export const DefaultColorPreset = ColorPresetThemes[DefaultColorPresetTheme];

export const AllColorPresets = Object.values(ColorPresetThemes);

export function isApe(eligibility: ProductEligibility): boolean {
	return (
		eligibility === ProductEligibility.CompteTitre ||
		eligibility === ProductEligibility.CompteTitreAssuranceVieCapitalisation
	);
}

export const publicationValues = [
	"Sixtelekurs, REUTERS. Cours publié au moins une fois tous les 15 jours et tenu à la disposition du public en permanence",
	"Sixtelekurs, REUTERS, Bloomberg. Cours publié au moins une fois tous les 15 jours et tenu à la disposition du public en permanence",
	"Sixtelekurs, REUTERS. Cours publié au moins une fois tous les jours et tenu à la disposition du public en permanence",
	"Sixtelekurs, REUTERS, Bloomberg. Cours publié au moins une fois tous les jours et tenu à la disposition du public en permanence",
];

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			"ic-account-single-picker": any;
			"ic-contact-multi-picker": any;
		}
	}
}

export type BrochureGeneratorAppState = BaseApplicationState<GlobalConfiguration> & {
	authorization: AuthorizationState;
	homepage: HomePageState;
	athena: AthenaState;
	tarn: TarnState;
	tauxFixe: TauxFixeState;
	phoenixBearish: PhoenixBearishState;
	resources: ResourcesState;
};

export type AppliedFiltersT = { key: string; value?: string; label: string }[];
