import { isNil } from "lodash";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ColorSelector } from "components/Shared/ColorSelector/ColorSelector";
import { PictureSelector } from "components/Shared/PictureSelector/PictureSelector";
import { cssHelper } from "components/Shared/cssHelper";
import { BrochureGeneratorAppState, publicationValues, StrikeFeature } from "Domain/report";
import { editValueCreatorHelper } from "state/Reports/Athena/Athena.state";
import { useAthenaValidationStateForField } from "state/Reports/Athena/useAthenaValidationStateForField";
import { ReportFormSection } from "../ReportFormSection";
import AthenaFormControl from "./Form/AthenaFormControl";
import { AthenaScheduleLines } from "./Form/AthenaScheduleLines";
import { Checkbox } from "components/Shared/FormControls/Checkbox";
import { Picker } from "components/Shared/FormControls/Picker";

export const LiquidityValuesHelper: React.FC<{ onSelect: (value: string) => void }> = ({ onSelect }) => {
	return (
		<Picker
			name="liquidityValues"
			singleSelect
			onChange={(selected) => { onSelect(selected[0]); }}
			items={publicationValues.map((v) => ({
				key: v,
				label: v,
			}))}
			createTag={false}
			placeholder="Select publication of liquidative value"
			style={{ flex: "0 1 250px" }}
			initialValue={[]}
		/>
	);
};

export const AthenaForm: FC = () => {
	const dispatcher = useDispatch();
	const athenaState = useSelector((a: BrochureGeneratorAppState) => a.athena);
	const pictureErrorState = useAthenaValidationStateForField("clientConfiguration", "pictureId");
	const colorErrorState = useAthenaValidationStateForField("clientConfiguration", "colorPreset");

	const { currentReport } = athenaState;

	const { hasMarketingPeriod, productEligibility } = currentReport.transversalConfiguration;
	const { strikeFeature, isAutocallDegressive, isOxygen, isLeveraged, isCapitalGuarantee } =
		currentReport.redemptionConfiguration;
	const { underlyings } = currentReport.underlyingConfiguration;
	const isStrikeFeatureEnabled = strikeFeature !== StrikeFeature.None;

	if (isNil(currentReport)) {
		return null;
	}

	return (
		<div className="position-absolute w-100 h-100 px-3 py-4" style={{ overflowY: "auto" }}>
			<div className="row  mb-4">
				<div className="col">
					<ReportFormSection title={"Product Information"}>
						<div className="row">
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productName"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="issuePrice"
								size={{ defaultSize: 12, lg: 3 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="priceRate"
								size={{ defaultSize: 12, lg: 3 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productIssuer"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="minimumInvestment"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productGuarantor"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="issueDate"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productCurrency"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="maturityDate"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productDenomination"
								size={{ defaultSize: 12, lg: 6 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productDuration"
								size={{ defaultSize: 12, lg: 6 }}
							/>
						</div>
					</ReportFormSection>
				</div>
				<div className="col">
					<div className="card card-bordered bg-lvl2 h-100 mb-4">
						<div className="card-body pb-0">
							<div className="d-flex flex-column h-100">
								<div className="form-label">Schedule Lines</div>
								<div className="flex-fill mb-3">
									<AthenaScheduleLines />
								</div>
								<div>
									<div className="row">
										<AthenaFormControl
											contextKey="redemptionConfiguration"
											field={"capitalPaymentBusinessDays"}
											size={6}
										/>
										<AthenaFormControl
											contextKey="redemptionConfiguration"
											field={"couponPaymentBusinessDays"}
											size={6}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<ReportFormSection title={"PayOff Information"}>
						<div className="row">
							<AthenaFormControl contextKey="underlyingConfiguration" field={"underlyings"} />
						</div>
						<div className="row">
							<AthenaFormControl
								contextKey="underlyingConfiguration"
								field={"indexationType"}
								size={6}
								fieldConfigOverrides={{ disabled: underlyings.length <= 1 }}
							/>
						</div>
						<div className="row">
							<AthenaFormControl contextKey="redemptionConfiguration" field={"autocallBarrier"} />
							<AthenaFormControl contextKey="redemptionConfiguration" field={"isCapitalGuarantee"} />
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field={"capitalProtectionBarrier"}
								fieldConfigOverrides={{ disabled: isCapitalGuarantee === true }}
							/>
							<AthenaFormControl contextKey="redemptionConfiguration" field={"coupon"} />
						</div>
						<div className="row">
							<AthenaFormControl contextKey="redemptionConfiguration" field={"totalPeriods"} />
							<AthenaFormControl contextKey="redemptionConfiguration" field={"observationStartPeriod"} />
							<AthenaFormControl contextKey="redemptionConfiguration" field={"observationPeriodicity"} />
							<AthenaFormControl contextKey="redemptionConfiguration" field={"feesPercent"} />
						</div>
						<div className="row">
							<AthenaFormControl contextKey="redemptionConfiguration" field={"isAutocallDegressive"} size={3} />
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field={"degressivityStep"}
								size={3}
								fieldConfigOverrides={{ disabled: !isAutocallDegressive }}
							/>
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field={"degressivityPeriod"}
								size={3}
								fieldConfigOverrides={{ disabled: !isAutocallDegressive }}
							/>
						</div>
						<div className="row">
							<AthenaFormControl contextKey="redemptionConfiguration" field={"isOxygen"} />
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field={"oxygenBarrier"}
								fieldConfigOverrides={{ disabled: !isOxygen }}
							/>
							<AthenaFormControl contextKey="redemptionConfiguration" field={"isLeveraged"} />
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field={"leverageAmount"}
								fieldConfigOverrides={{ disabled: !isLeveraged }}
							/>
						</div>
						<div className="row">
							<div className={cssHelper.colSizeClassNames(3)}>
								<div className="form-label d-block "> {"\u00A0"}</div>
								<Checkbox
									initialValue={isStrikeFeatureEnabled}
									onChange={(_) => {
										dispatcher(
											editValueCreatorHelper(
												"redemptionConfiguration",
												"strikeFeature",
												isStrikeFeatureEnabled === true ? StrikeFeature.None : StrikeFeature.Min,
											),
										);
									}}
									label={"Has Strike Min/Mid ?"}
									id={"fieldStrikeMinMid"}
									isValid={true}
								/>
							</div>
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field="strikeFeature"
								size={3}
								fieldConfigOverrides={{ className: "d-flex", disabled: !isStrikeFeatureEnabled }}
							/>
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field="strikeDates"
								fieldConfigOverrides={{ disabled: !isStrikeFeatureEnabled }}
							/>
							<div className="col-6"></div>
							<AthenaFormControl
								contextKey="redemptionConfiguration"
								field="strikeInitialPaiementDate"
								fieldConfigOverrides={{ disabled: !isStrikeFeatureEnabled }}
							/>
						</div>
					</ReportFormSection>
				</div>
				<div className="col">
					<ReportFormSection title={"Deal information"}>
						<div className="row">
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productCotation"
								size={{ defaultSize: 12, lg: 4 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productLaw"
								size={{ defaultSize: 12, lg: 4 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="bidOfferPercent"
								size={{ defaultSize: 12, lg: 4 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="productEligibility"
								size={{ defaultSize: 12, lg: 4 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="commission"
								size={{ defaultSize: 12, lg: 4 }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="commissionType"
								size={{ defaultSize: 12, lg: 4 }}
							/>
							<AthenaFormControl contextKey="transversalConfiguration" field="hasMarketingPeriod" size={3} />
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="marketingPeriod"
								size={5}
								fieldConfigOverrides={{ hidden: !hasMarketingPeriod }}
							/>
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="doubleValuation"
								size={{ defaultSize: 12, lg: 4 }}
							/>
						</div>
						<div className="row">
							<AthenaFormControl contextKey="transversalConfiguration" field="mtmPublication" />
							<div className={cssHelper.colSizeClassNames(4, 'mb-3')} style={{ maxWidth: 500 }}>
								<div className="form-label">&nbsp;</div>
								<LiquidityValuesHelper
									onSelect={(val) =>
										dispatcher(editValueCreatorHelper("transversalConfiguration", "mtmPublication", val))
									}
								/>
							</div>
						</div>
						<div className="row">
							<AthenaFormControl contextKey="transversalConfiguration" field="isin" size={{ defaultSize: 12, lg: 4 }} />
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="calculationAgent"
								size={{ defaultSize: 12, lg: 4 }}
							/>
							<AthenaFormControl contextKey="clientConfiguration" field={"client"} size={{ defaultSize: 12, lg: 4 }} />

							<AthenaFormControl contextKey="transversalConfiguration" field="initialValuationDate" size={4} />
							<AthenaFormControl
								contextKey="transversalConfiguration"
								field="ftDate"
								size={{ defaultSize: 12, lg: 4 }}
								fieldConfigOverrides={{ hidden: productEligibility == null }}
							/>
						</div>
						<div className="row">
							<div className={cssHelper.colSizeClassNames({ sm: 12, md: 6 }, 'mb-3')}>
								<div className="form-label">Cover Picture *</div>
								<PictureSelector
									selectedPictureId={currentReport.clientConfiguration.pictureId}
									onChange={(id) => {
										dispatcher(editValueCreatorHelper("clientConfiguration", "pictureId", id));
									}}
									errorMessage={pictureErrorState}
								/>
							</div>
							<div className={cssHelper.colSizeClassNames({ sm: 12, md: 6 }, 'mb-3')}>
								<div className="form-label">Client Theme *</div>
								<ColorSelector
									errorMessage={colorErrorState}
									headerTitle="Client Theme"
									selectedPreset={currentReport.clientConfiguration.colorPreset}
									onChange={(preset) => {
										dispatcher(editValueCreatorHelper("clientConfiguration", "colorPreset", preset));
									}}
								/>
							</div>
						</div>
					</ReportFormSection>
				</div>
			</div>
		</div>
	);
};
