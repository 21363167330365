import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as immer from "immer";
import { getAthenaFormFieldConfiguration } from "./getAthenaFormFieldConfiguration";
import { Schema } from "yup";
import { getAthenaValidationSchema } from "./getAthenaValidationSchema";
import { createEmptyAthenaReport } from "./createDefaultAthenaReport";
import { cloneDeep, range, groupBy, assign, NumericDictionary, reduce } from "lodash";
import { UnderlyingIndexationType, isApe, ProductEligibility } from "Domain/report";
import {
	AthenaReportConfiguration,
	AthenaUnderlyingId,
	AthenaScheduleLine,
	AthenaRedemptionConfiguration,
	AthenaTransversalConfiguration,
} from "Domain/report.athena";
import { validateModel } from "Helpers/validationHelpers";
import { LoadingState } from "Models/LoadingState";
import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";
import { computeDuration } from "Mappers/Tarn/tarnTransversalConfigurationMapper";

export type AthenaState = {
	formState: AthenaFormState;
	initialReport: AthenaReportConfiguration;
	currentReport: AthenaReportConfiguration;
	brochureLoadingState: LoadingState;
	tradeReference: string | undefined;
	availableUnderlyings: AthenaUnderlyingId[];
};

export type AthenaReportValidationSchema = Schema<AthenaReportConfiguration>;

export type AthenaFormState = {
	fieldConfiguration: FieldConfigurationFromModel<AthenaReportConfiguration>;
	validationSchema: AthenaReportValidationSchema;
	validationState: Record<string, string>;
};

const defaultState: AthenaState = {
	formState: {
		fieldConfiguration: getAthenaFormFieldConfiguration(),
		validationSchema: getAthenaValidationSchema(),
		validationState: {},
	},
	initialReport: createEmptyAthenaReport().configuration,
	currentReport: createEmptyAthenaReport().configuration,
	brochureLoadingState: LoadingState.Idle,
	tradeReference: undefined,
	availableUnderlyings: [],
};

interface EditReportConfigurationValuePayload<
	TReportConfigKey extends keyof AthenaReportConfiguration,
	TFieldKey extends keyof AthenaReportConfiguration[TReportConfigKey],
> {
	contextKey: TReportConfigKey;
	field: TFieldKey;
	value: AthenaReportConfiguration[TReportConfigKey][TFieldKey];
}

const AthenaSlice = createSlice({
	initialState: defaultState,
	name: "athenaReport",
	reducers: {
		editValue: <
			TReportConfigKey extends keyof AthenaReportConfiguration,
			TFieldKey extends keyof AthenaReportConfiguration[TReportConfigKey],
		>(
			state: immer.Draft<AthenaState>,
			action: PayloadAction<EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey>>,
		) => {
			const reportConfiguration = state.currentReport;
			const { contextKey, field, value } = action.payload;
			if (reportConfiguration) {
				reportConfiguration[contextKey][field] = value;

				if (contextKey === "underlyingConfiguration") {
					const typedField = field as keyof AthenaReportConfiguration["underlyingConfiguration"];

					if (typedField === "underlyings") {
						const underlyings = reportConfiguration.underlyingConfiguration.underlyings;
						const indexationType = reportConfiguration.underlyingConfiguration.indexationType;
						if (underlyings.length > 1 && indexationType === UnderlyingIndexationType.None) {
							reportConfiguration.underlyingConfiguration.indexationType = UnderlyingIndexationType.Basket;
						} else {
							if (underlyings.length === 1 && indexationType !== UnderlyingIndexationType.None) {
								reportConfiguration.underlyingConfiguration.indexationType = UnderlyingIndexationType.None;
							}
						}
					}
				} else if (contextKey === "redemptionConfiguration") {
					const typedField = field as keyof AthenaReportConfiguration["redemptionConfiguration"];

					if (typedField === "oxygenBarrier") {
						reportConfiguration.redemptionConfiguration.isOxygen = (value as number) > 0;
					}
					if (typedField === "scheduleLines") {
						const newSl = value as AthenaScheduleLine[];
						reportConfiguration.redemptionConfiguration.scheduleLines = cloneDeep(newSl);
					}
					if (typedField === "isCapitalGuarantee") {
						if (value === true) {
							reportConfiguration.redemptionConfiguration.capitalProtectionBarrier = 0;
						}
					}

					const fieldsThatRecompute: Array<keyof AthenaRedemptionConfiguration> = [
						"autocallBarrier",
						"observationStartPeriod",
						"observationPeriodicity",
						"coupon",
						"isAutocallDegressive",
						"degressivityStep",
						"degressivityPeriod",
						"totalPeriods",
					];

					if (fieldsThatRecompute.includes(typedField)) {
						reportConfiguration.redemptionConfiguration.scheduleLines = recomputeScheduleLines(
							immer.current(reportConfiguration.redemptionConfiguration)
						);
					}
				} else if (contextKey === "transversalConfiguration") {
					const typedField = field as keyof AthenaReportConfiguration["transversalConfiguration"];
					if (typedField === "productEligibility") {
						if (!isApe(value as ProductEligibility)) {
							reportConfiguration.transversalConfiguration.ftDate = undefined;
						}
					}
					const durationToComputeKeys: Array<keyof AthenaTransversalConfiguration> = ["issueDate", "maturityDate"];
					if (durationToComputeKeys.includes(field as keyof AthenaTransversalConfiguration)) {
						state.currentReport.transversalConfiguration.productDuration = computeDuration(
							state.currentReport.transversalConfiguration.issueDate,
							state.currentReport.transversalConfiguration.maturityDate,
						);
					}
				}
				state.formState.validationState =
					validateModel(
						immer.current(state.currentReport),
						state.formState.validationSchema as unknown as Schema<AthenaReportConfiguration>,
					) || {};
			}
		},
		generateBrochure: (state) => {
			const validationState = validateModel(
				state.currentReport,
				state.formState.validationSchema as unknown as Schema<AthenaReportConfiguration>,
			);
			if (validationState) {
				state.formState.validationState = validationState;
			} else {
				state.formState.validationState = {};
				state.brochureLoadingState = LoadingState.Pending;
			}
		},
		generateBrochureSuccess: (state) => {
			state.brochureLoadingState = LoadingState.Resolved;
		},
		generateBrochureError: (state) => {
			state.brochureLoadingState = LoadingState.Error;
		},

		initReport: (_) => {},
		resetReport: (state) => {
			state.currentReport = cloneDeep(state.initialReport);
			state.formState.validationState =
				validateModel(
					state.currentReport,
					state.formState.validationSchema as unknown as Schema<AthenaReportConfiguration>,
				) || {};
		},
		initReportSuccess: (
			state,
			action: PayloadAction<{ reportConfiguration: AthenaReportConfiguration; tradeReference: string | undefined }>,
		) => {
			state.initialReport = cloneDeep(action.payload.reportConfiguration);
			state.currentReport = cloneDeep(action.payload.reportConfiguration);
			state.tradeReference = action.payload.tradeReference;
			state.formState.validationState = {};
		},
		initReportNotManaged: (_) => {},
		loadAvailableUnderlyings: (_) => {},
		loadAvailableUnderlyingsSuccess: (state, action: PayloadAction<{ underlyings: AthenaUnderlyingId[] }>) => {
			state.availableUnderlyings = action.payload.underlyings;
		},
	},
});

function createAutocallSerie(
	totalPeriods: number,
	observationStartPeriod: number,
	autocall: number,
	degressivityStep: number | undefined,
	degressivityPeriodicity: number | undefined,
): NumericDictionary<number | undefined> {
	const autocalls = range(1, totalPeriods + 1).map((period) => {
		let autocallForPeriod: number | undefined = autocall;
		if (period < observationStartPeriod || period === totalPeriods) {
			autocallForPeriod = undefined;
		} else {
			if (degressivityStep && degressivityPeriodicity) {
				const a = period - observationStartPeriod;
				if (a > 0 && Math.floor(a / degressivityPeriodicity) > 0) {
					autocallForPeriod = autocall - Math.floor(a / degressivityPeriodicity) * degressivityStep;
				}
			} else {
				autocallForPeriod = autocall;
			}
		}
		return { period, autocall: autocallForPeriod };
	});

	const result: NumericDictionary<number | undefined> = {};

	return reduce(
		autocalls,
		(acc, val) => {
			acc[val.period] = val.autocall;
			return acc;
		},
		result,
	);
}

function createCouponSerie(
	totalPeriods: number,
	observationStartPeriod: number,
	coupon: number,
): NumericDictionary<number | undefined> {
	const autocalls = range(1, totalPeriods + 1).map((period) => {
		let couponForPeriod: number | undefined;
		if (period < observationStartPeriod) {
			couponForPeriod = undefined;
		} else {
			couponForPeriod = period * coupon;
		}
		return { period, coupon: couponForPeriod };
	});

	const result: NumericDictionary<number | undefined> = {};

	return reduce(
		autocalls,
		(acc, val) => {
			acc[val.period] = val.coupon;
			return acc;
		},
		result,
	);
}

function recomputeScheduleLines(redemptionConfig: AthenaRedemptionConfiguration): AthenaScheduleLine[] {
	const {
		totalPeriods,
		coupon,
		autocallBarrier,
		scheduleLines,
		observationStartPeriod,
		degressivityPeriod,
		degressivityStep,
	} = redemptionConfig;
	const allAutocalls = createAutocallSerie(
		totalPeriods,
		observationStartPeriod,
		autocallBarrier,
		degressivityStep,
		degressivityPeriod?.duration,
	);
	const allCoupon = createCouponSerie(totalPeriods, observationStartPeriod, coupon);
	const oldLines = groupBy(scheduleLines, (sl) => sl.periodNumber);
	const newLines: Partial<AthenaScheduleLine>[] = range(1, totalPeriods + 1).map((periodNumber) => {
		const newItem: Partial<AthenaScheduleLine> = {
			periodNumber,
			autocall: allAutocalls[periodNumber],
			coupon: allCoupon[periodNumber],
		};
		if (oldLines[periodNumber] !== undefined) {
			return assign({}, oldLines[periodNumber][0], newItem);
		}
		return newItem;
	});

	return newLines as AthenaScheduleLine[];
}

export const editValueCreatorHelper = <
	TReportConfigKey extends keyof AthenaReportConfiguration,
	TFieldKey extends keyof AthenaReportConfiguration[TReportConfigKey],
>(
	key: TReportConfigKey,
	field: TFieldKey,
	value: AthenaReportConfiguration[TReportConfigKey][TFieldKey],
) => {
	const payload: EditReportConfigurationValuePayload<TReportConfigKey, TFieldKey> = {
		contextKey: key,
		field,
		value,
	};
	return AthenaSlice.actions.editValue(payload as any);
};

export default AthenaSlice;
