import { AthenaReportConfiguration } from "Domain/report.athena";
import { useMemo } from "react";
import { isEmpty, chain, isMatch } from "lodash";
import { useSelector } from "react-redux";
import { BrochureGeneratorAppState } from "Domain/report";
import { GLOBAL_VALIDATION_FIELD } from "Helpers/validationHelpers";

export function useAthenaValidationStateForField<
	TReportConfigKey extends keyof AthenaReportConfiguration,
	TFieldKey extends keyof AthenaReportConfiguration[TReportConfigKey],
>(key: TReportConfigKey, field: TFieldKey): string | undefined {
	const validationState = useSelector((s: BrochureGeneratorAppState) => s.athena.formState.validationState);
	const fieldState = useMemo(
		() => getFielValidationState(validationState, key, field as string),
		[field, key, validationState],
	);
	return fieldState;
}

export function getFielValidationState(
	validationState: Record<string, string>,
	fieldContext: string,
	field: string,
): string | undefined {
	const fullPath = [fieldContext, field];
	const result: string[] = chain(validationState)
		.reduce((acc: string[], value, key) => {
			if (isMatch(key.split("."), fullPath)) {
				acc.push(value);
			}
			return acc;
		}, [])
		.uniq()
		.value();
	if (!isEmpty(result)) {
		return result.join(", ");
	}
	return undefined;
}

export function useGlobalAthenaValidationMessage(): string | undefined {
	const validationState = useSelector((s: BrochureGeneratorAppState) => s.athena.formState.validationState);
	return validationState[GLOBAL_VALIDATION_FIELD];
}
