import { SgCategoryPicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import React, { FC, useRef, useEffect } from "react";
import { BreakPointsName } from "../cssHelper";

// https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap/tree/main/%40sg-bootstrap/components/src/components/sg-category-picker

export type CategoryPickerItem = {
	key: string;
	label: string;
	children?: CategoryPickerItem[];
};

export interface CategoryPickerProps {
	placeholder?: string;
	className?: string;
	items: CategoryPickerItem[];
	singleSelect?: boolean;
	noCategorySelect?: boolean;
	closeAfterSelect?: boolean;
	size?: Exclude<BreakPointsName, "xs" | "xxl">;

	onItemSelected: (value: string | undefined) => void;
}

export const CategoryPicker: FC<CategoryPickerProps> = (props) => {
	const ref = useRef<HTMLSgCategoryPickerElement | null>(null);

	useEffect(() => {
		if (ref.current) {
			ref.current.setItems(props.items);
		}
	}, [ref, props.items]);

	return (
		<SgCategoryPicker
			ref={ref}
			singleSelect={props.singleSelect}
			placeholder={props.placeholder}
			onItemSelected={(e) => props.onItemSelected(e.detail.key?.toString() ?? undefined)}
			onSearchResultSelected={(e) => props.onItemSelected(e.detail.key?.toString() ?? undefined)}
			noCategorySelect={props.noCategorySelect}
			closeAfterSelect={props.closeAfterSelect}
			size={props.size}
		/>
	);
};
