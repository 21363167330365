import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrochureGeneratorAppState } from "Domain/report";
import { PhoenixBearishReportConfiguration } from "Domain/report.phoenixBearish";
import { getFielValidationState } from "../Athena/useAthenaValidationStateForField";

export function usePhoenixBearishValidationStateForField<
	TReportConfigKey extends keyof PhoenixBearishReportConfiguration,
	TFieldKey extends keyof PhoenixBearishReportConfiguration[TReportConfigKey],
>(key: TReportConfigKey, field: TFieldKey): string | undefined {
	const validationState = useSelector((s: BrochureGeneratorAppState) => s.phoenixBearish.formState.validationState);
	const fieldState = useMemo(
		() => getFielValidationState(validationState, key, field as string),
		[field, key, validationState],
	);
	return fieldState;
}
